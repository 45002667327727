// src/components/home/PlanList.js
import React from 'react';
import { logInDevelopment, getCookie, setCookie } from '../../utils';

const PlanList = ({
  productData,
  subscriptionPlanId,
  hasSubscription,
  subscriptionPrice,
  handleUpgradeClick,
  handleCancelClick,
  loading,
  cookieValue,
  product,
  apiData
}) => {
  const filteredCProducts = apiData ? apiData.filter(p => p.productName === product) : [];
  logInDevelopment("Pricing...");
  const handleStartTrialNowClick = async (subsId) => {
    setCookie(subsId, 'trial', 90);
    const cookieValue = getCookie(subsId);

    if (cookieValue === 'trial') {
      // If the cookie exists, redirect to the URL
      let apiRedirect = 'api-details#api=stable-diffusion-xl-base-10&operation=get-image';

      if (filteredCProducts.length > 0) {
        const product = filteredCProducts[0];
        apiRedirect = product.testYourApi || apiRedirect;
      }
      window.location.href = `https://endpoint-console.appypie.com/login?returnUrl=/${apiRedirect}`;
    } else {
      setCookie(subsId, 'trial', 90);
    }
  };

  return (
    <>
    <div class="plan-header mb-5 pb-4">
            <h2 class="page-title">Pricing</h2>
        </div>
    <div className="payment-plan-wrapper">
        <div className="plan-inner-wrapper">
        <div className="card-list-wrap">
      {productData.map((product, index) => (
        <div className="card-item" key={index}>
          <div className={`plan-card ${product.planName.toLowerCase() === 'standard' && subscriptionPlanId !== product.planId ? 'recommended-plan':''} ${subscriptionPlanId === product.planId ? 'active' : ''}` }>
          <div className="plan-card-header">
      {product.planName.toLowerCase() === 'standard' && subscriptionPlanId !== product.planId && (
        <span className="badge badge-top">Recommended</span>
      )}
          {subscriptionPlanId === product.planId  && (product.planId !== 'free' || cookieValue === 'trial' ) ? (
            <span className="badge badge-top">
              Current Plan
            </span>
          ):(null)}
            <div className="price-wrap">
              {product.planId === 'free' ?(
                <>
                <h3 className="best-deal basic-plan-title viewonly"></h3>
                <h2><sup>$</sup>Free</h2>
                </>
              ):(
                <>
                <h3 className={`best-deal ${product.planName.toLowerCase()}-plan-title`}>{product.planName}</h3>
                <h2><sup>$</sup>{product.price}<sub>/month</sub></h2>
                </>
              )}
              
            </div>
          </div>
        <div className="plan-card-body">
            <div className="checks-list">
              <ul>
                {product.properties && product.properties.map((property, idx) => (
                  <li key={idx}>{property}</li>
                ))}
              </ul>
            </div>
            {!hasSubscription || subscriptionPrice < product.price ? (
            <div className="button-wrap">
                <button className={product.planName.toLowerCase() === 'standard' ? 'card-cta card-cta-o' : 'card-cta'}
                        onClick={() => handleUpgradeClick(product)}
                        disabled={!productData.length || loading === product.planId}>
                    {loading === product.planId ? (
                        <div className="loader"></div>
                    ) : (
                      <span>{subscriptionPrice > 0 ? 'Upgrade Now' : 'Subscribe Now'}</span>
                    )}
                </button>
            </div>
          ) : (
                subscriptionPlanId === product.planId && product.planId === 'free'  ? (
                    <div className="button-wrap">
                      {cookieValue !== 'trial' ?(
                          <button className="card-cta-bordered cta-free"
                          onClick={() => handleStartTrialNowClick(product.productName)}
                            disabled={!productData.length || loading === product.planId}>
                        {loading === product.planId ? (
                            <div className="loader"></div>
                        ) : (
                            <span>Start Trial Now </span>
                        )}
                          </button>
                      ):(null)}
                    </div>
                ) : (
                  subscriptionPlanId === product.planId &&  product.planId !== 'free' ? (
                  <div className="button-wrap">
                    <button className="card-cta-bordered "
                          onClick={() => handleCancelClick(product)}
                          disabled={!productData.length || loading === product.planId}>
                      {loading === product.planId ? (
                          <div className="loader"></div>
                      ) : (
                          <span>Cancel </span>
                      )}
                    </button>
                </div>
                  ):(null)
                )
            )}
        </div>  
        </div>
        </div>
      ))}
    </div>
    {/*
      <div className="skip-price"><a href="https://endpoint-console.appypie.com/login?returnUrl=/api-details#api=stable-diffusion-xl-base-10&operation=get-image">Skip</a></div>
    */}
    </div>
    </div>
    </>
  );
};

export default PlanList;