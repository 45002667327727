import React, { useContext, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import config from '../../config';
import { RingLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import CryptoJS from 'crypto-js';
import { logInDevelopment, getCookie, setCookie } from '../../utils';
import PlanList from '../../components/home/PlanList';
import ApiInfo from '../../components/home/ApiInfo';
import ProductDropDown from '../../components/home/ProductDropDown';
import ErrorBoundary from '../../components/ErrorBoundary'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import mixpanel from "mixpanel-browser";

const HomePage = () => {

// Near entry of your product, init Mixpanel
// create an instance of the Mixpanel object
mixpanel.init("24af23e0147747e9f01f51c5693e313e", {
    debug: true, // enable debug log in console
    track_pageview: true,
    persistence: "localStorage", // set persistence to local storage
  });

    const { isAuthenticated, userDetails, userFromApi } = useContext(AuthContext);
    const [state, setState] = useState({
        cookieValue: null,
        currentUsages: null,
        productData: [],
        product: null,
        hasSubscription: false,
        subscriptionName: '',
        subscriptionLimit: 0,
        subscriptionId: '',
        subscriptionPrice: 0,
        subscriptionPlanId: '',
        loading: null,
        showLoader: true,
        refreshLoading: false,
        subscriptionCreated: false,
        loadingMessage: 'Loading the Plan...',
        coupon: '',
        apiData: null,
        hideFreePlanInfo: 0
    });
    const [subscriptionProcessStarted, setSubscriptionProcessStarted] = useState(false);
    const mode = process.env.REACT_APP_STAGE || 'development';
    const refreshButtonRef = useRef(null);
    const location = useLocation();
    const ENCRYPTION_KEY = 'HareRamaHareKrishna';

    // Encryption and decryption functions
    const encryptData = (data) => CryptoJS.AES.encrypt(data.toString(), ENCRYPTION_KEY).toString();
    const encryptJSONData = (data) => CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
    const decryptData = (encryptedData) => {
        const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    const resetAllStates = () => {
        setState({
            cookieValue: null,
            currentUsages: null,
            productData: [],
            product: null,
            hasSubscription: false,
            subscriptionName: '',
            subscriptionLimit: 0,
            subscriptionId: '',
            subscriptionPrice: 0,
            subscriptionPlanId: '',
            loading: null,
            showLoader: true,
            refreshLoading: false,
            subscriptionCreated: false,
            loadingMessage: 'Loading the Plan...',
            coupon: '',
            apiData: null,
            hideFreePlanInfo: 0
        });
        setSubscriptionProcessStarted(false);
    };

    const initializeProductData = (productParam) => {
        if (productParam) {
            setState(prevState => ({ ...prevState, product: productParam }));
            logInDevelopment('Product parameter from URL (initializeProductData):', productParam);
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const productParam = queryParams.get('product');
        const cookieValue = getCookie(productParam);
        logInDevelopment("cookieValue= ", cookieValue);
        setState(prevState => ({ ...prevState, cookieValue }));
        initializeProductData(productParam);
    }, [location.search]);

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                logInDevelopment('Fetching product data for:', state.product);
                const productResponse = await axios.post(`${config.ENDPOINT_API_URL}/getProduct`, {
                    productName: state.product,
                    status: 'active',
                    mode: mode
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Ocp-Apim-Subscription-Key': '99c4cfca3a2c48409c095fb1b4689f4b'
                    }
                });
                logInDevelopment('Product response:', productResponse.data);

                if (productResponse.data.documents && productResponse.data.documents.length > 0) {
                    const validProducts = productResponse.data.documents;
                    logInDevelopment("Filtered product details:", validProducts);
                    setState(prevState => ({ ...prevState, productData: validProducts }));
                } else {
                    toast.success("No valid product was found.");
                    setTimeout(() => {
                        window.location.href = `${config.MARKETING_URL}`;
                    }, 2000);
                }
            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };

        if (state.product && state.productData.length === 0) {
            logInDevelopment('Product found in URL:', state.product);
            fetchProductData();
        } else {
            logInDevelopment('No product found in URL or product data already set');
        }
    }, [state.product, state.productData]);

    useEffect(() => {
        const fetchUserProductSubscription = async (userId, productName) => {
            try {
                if (!subscriptionProcessStarted) {
                    setState(prevState => ({ ...prevState, loadingMessage: 'Loading user...' }));
                }
                logInDevelopment('Checking user product subscription for:', productName);
                const response = await axios.post(`${config.ENDPOINT_API_URL}/ActiveSubUser`, {
                    userId: userId,
                    productName: productName,
                    status: 'active',
                    appid: ''
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                logInDevelopment('User product subscription response:', response.data);
                if (response.data && response.data.length > 0) {
                    const subscription = response.data[0];
                    setState(prevState => ({
                        ...prevState,
                        hasSubscription: true,
                        subscriptionName: subscription.planName,
                        subscriptionLimit: subscription.apiLimit,
                        subscriptionId: subscription.subscriptionId,
                        subscriptionPrice: subscription.price,
                        subscriptionPlanId: subscription.planId,
                        subscriptionCreated: true
                    }));
                    return subscription.planId;
                } else {
                    logInDevelopment('No active subscription found. Creating a new free subscription.');
                    setState(prevState => ({ ...prevState, loadingMessage: 'Creating Subscription...' }));
                    setSubscriptionProcessStarted(true);
                    const subId = 'api_' + Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000 + '_' + Math.floor(Date.now() / 1000);
                    const createResponse = await axios.post(`${config.ENDPOINT_API_URL}/azureFreeSub`, {
                        subscriptionId: subId,
                        displayName: 'planId-free',
                        ownerId: userId,
                        scope: productName
                    }, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    logInDevelopment('Free subscription creation response:', createResponse.data);
                    if (createResponse.data && createResponse.data.token) {
                        setState(prevState => ({ ...prevState, subscriptionCreated: true }));
                        return fetchUserProductSubscription(userId, productName);
                    } else {
                        setState(prevState => ({ ...prevState, subscriptionCreated: false }));
                    }
                }
            } catch (error) {
                console.error('Error checking user product subscription:', error);
                setState(prevState => ({ ...prevState, hasSubscription: false, subscriptionCreated: false }));
                return false;
            }
        };

        const fetchUserData = async (productData, planId) => {
            try {
                if (!subscriptionProcessStarted) {
                    setState(prevState => ({ ...prevState, loadingMessage: 'Loading user...' }));
                }
                logInDevelopment('Fetching user data for product:', productData[0].productName);
                if (isAuthenticated && userFromApi) {
                    const userId = userFromApi.document.name;
                    logInDevelopment('User ID:', userId);
                    const subscriptionExists = await fetchUserProductSubscription(userId, productData[0].productName);
                    if (subscriptionExists) {
                        logInDevelopment('subscriptionPlanId=', state.subscriptionPlanId);
                        const response = await axios.post(`${config.ENDPOINT_API_URL}/getdata`, {
                            userId: `/users/${userId}`,
                            productId: `/products/${productData[0].productName}`,
                            planId: 'planId-' + planId || ''
                        });
                        logInDevelopment('User data response:', response.data);
                        setState(prevState => ({ ...prevState, currentUsages: response.data.sumCallCountTotal }));
                    } else {
                        logInDevelopment('No valid subscription found, skipping getdata call');
                    }
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        if (state.productData.length > 0 && isAuthenticated && userFromApi) {
            (async () => {
                const planId = await fetchUserProductSubscription(userFromApi.document.name, state.productData[0].productName);
                await fetchUserData(state.productData, planId);
            })();
        }
    }, [isAuthenticated, userFromApi, state.productData, subscriptionProcessStarted]); // Added subscriptionProcessStarted as a dependency

    useEffect(() => {
        const checkSubscriptionCreation = setInterval(() => {
            if (state.subscriptionPlanId === 'free' && state.cookieValue !== 'trial') {
                const top_menu = document.getElementById('endpoint_top_menu');
                if (top_menu) {
                    top_menu.style.display = 'none';
                }
            }
            if (state.subscriptionCreated) {
                if (state.hasSubscription) {
                    setState(prevState => ({ ...prevState, showLoader: false }));
                    clearInterval(checkSubscriptionCreation);
                } else {
                    setTimeout(() => {
                        setState(prevState => ({ ...prevState, showLoader: false }));
                        clearInterval(checkSubscriptionCreation);
                        if (refreshButtonRef.current) {
                            refreshButtonRef.current.click();
                        }
                    }, 2000);
                }
            }
        }, 1000);
        return () => clearInterval(checkSubscriptionCreation);
    }, [state.subscriptionCreated, state.hasSubscription, state.subscriptionPlanId]);

    const handleCancelClick = async (product) => {
        setState(prevState => ({ ...prevState, loading: product.planId }));
        logInDevelopment('subscriptionId :', state.subscriptionId);
        const subscriptionData = {
            snd: state.subscriptionId,
            email: userDetails.email
        };
        const encryptedData = encryptJSONData(subscriptionData);
        try {
            const couponResponse = await axios.post(`${config.ENDPOINT_API_URL}/9337223457`, {
                encpayload: encryptedData
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                }
            });
            logInDevelopment('couponResponse after cancel:', couponResponse.data);
            if (couponResponse.data && couponResponse.data.success) {
                resetAllStates();
                const queryParams = new URLSearchParams(location.search);
                const productParam = queryParams.get('product');
                initializeProductData(productParam);
                toast.success("Subscription cancelled");
            } else {
                toast.error("Failed to cancel subscription");
            }
        } catch (error) {
            console.error('Error during subscription cancellation:', error);
            toast.error("An error occurred. Please try again.");
        } finally {
            setState(prevState => ({ ...prevState, loading: null }));
        }
    };

    const handleUpgradeClick = async (product) => {
        setState(prevState => ({ ...prevState, loading: product.planId }));
        const queryParams = new URLSearchParams(location.search);
        const apiParam = queryParams.get('api');
        const operationParam = queryParams.get('operation');
        if (!userFromApi || !userFromApi.document || !userFromApi.document.name) {
            console.error('Error: User information is missing');
            setState(prevState => ({ ...prevState, loading: null }));
            return;
        }
        const userId = userFromApi.document.name;
        const newSubscriptionId = 'api_' + Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000 + '_' + Math.floor(Date.now() / 1000);
        const finalSubscriptionId = state.hasSubscription ? state.subscriptionId : newSubscriptionId;
        const returnUrl = `api=${apiParam}&operation=${operationParam}`;
        if (product.planId === 'free') {
            try {
                logInDevelopment('User from API:', userFromApi);
                const response = await axios.post(`${config.ENDPOINT_API_URL}/azureFreeSub`, {
                    subscriptionId: finalSubscriptionId,
                    displayName: 'planId-free',
                    ownerId: userId,
                    scope: product.productName
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                logInDevelopment('API response:', response.data);
                if (response.data && response.data.token) {
                    const sharedAccessToken = response.data.token;
                    const redirectUrl = `https://endpoints.appypie.com/signin-sso?token=${sharedAccessToken}&returnUrl=${encodeURIComponent(returnUrl)}`;
                    window.location.href = redirectUrl;
                } else {
                    console.error('Error: No token received from API');
                }
            } catch (error) {
                console.error('Error calling azureFreeSub API:', error);
            } finally {
                setState(prevState => ({ ...prevState, loading: null }));
            }
        } else {
            try {
                const subscriptionLimitLeftCost = calculateSubscriptionLimitLeftCost();
                logInDevelopment('subscriptionLimitLeftCost:', subscriptionLimitLeftCost);
                if (subscriptionLimitLeftCost > 0) {
                    const encryptedCost = encryptData(subscriptionLimitLeftCost);
                    const couponResponse = await axios.post(`${config.ENDPOINT_API_URL}/728738732873273`, {
                    //const couponResponse = await axios.post('http://localhost:8787/api/728738732873273', {
                        limitLeft: encryptedCost,
                        pg: product.productGroup
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache'
                        }
                    });
                    logInDevelopment('Coupon API response:', couponResponse.data);
                    if (couponResponse.data && couponResponse.data.hash) {
                        logInDevelopment('couponResponse.data.hash:', couponResponse.data.hash);
                        setState(prevState => ({ ...prevState, coupon: couponResponse.data.hash }));
                        const url = `${config.SNAPPY_URL}/index/endpoint?pg=${product.productGroup}&p=${product.productName}&pd=${product.planId}&khash=${userId}&sd=${finalSubscriptionId}&${returnUrl}`;
                       window.location.href = `${url}&cp=${couponResponse.data.hash}`;
                    } else {
                        console.error('Error: No hash received from coupon API');
                    }
                } else {
                    setState(prevState => ({ ...prevState, coupon: '' }));
                    const url = `${config.SNAPPY_URL}/index/endpoint?pg=${product.productGroup}&p=${product.productName}&pd=${product.planId}&khash=${userId}&sd=${finalSubscriptionId}&${returnUrl}`;
                    window.location.href = url;
                }
            } catch (error) {
                console.error('Error calling coupon API:', error);
            } finally {
                setState(prevState => ({ ...prevState, loading: null }));
            }
        }
    };

    const handleRefreshClick = async () => {
        setState(prevState => ({ ...prevState, refreshLoading: true, currentUsages: null }));
        try {
            const userId = userFromApi.document.name;
            const response = await axios.post(`${config.ENDPOINT_API_URL}/getdata`, {
                userId: `/users/${userId}`,
                productId: `/products/${state.product}`,
                planId: `planId-${state.subscriptionPlanId}`
            });
            logInDevelopment('User data response:', response.data);
            setState(prevState => ({ ...prevState, currentUsages: response.data.sumCallCountTotal }));
        } catch (error) {
            console.error('Error refreshing user data:', error);
        } finally {
            setState(prevState => ({ ...prevState, refreshLoading: false }));
        }
    };

    const calculateUsagePercentage = () => {
        if (state.currentUsages !== null && state.subscriptionLimit !== 0) {
            return (state.currentUsages / state.subscriptionLimit) * 100;
        }
        return 0;
    };

    const getFillColor = () => {
        const percentage = calculateUsagePercentage();
        if (percentage <= 70) {
            return '#099eff';
        } else if (percentage > 70 && percentage < 100) {
            return 'orange';
        } else {
            return 'red';
        }
    };

    const calculateSubscriptionLimitLeft = () => {
        return state.subscriptionLimit - state.currentUsages;
    };

    const calculateSubscriptionLimitLeftCost = () => {
        const limitLeft = calculateSubscriptionLimitLeft();
        const costPerCall = state.subscriptionPrice / state.subscriptionLimit;
        return Math.round(limitLeft * costPerCall);
    };

    const fetchCoupon = async (subscriptionLimitLeftCost) => {
        try {
            const encryptedCost = encryptData(subscriptionLimitLeftCost);
            const response = await axios.post(`${config.ENDPOINT_API_URL}/get42323223`, { limitLeft: encryptedCost });
            if (response.data && response.data.get42323223) {
                const decryptedCouponCode = decryptData(response.data.get42323223);
                setState(prevState => ({ ...prevState, coupon: decryptedCouponCode }));
                logInDevelopment('Coupon Code:', decryptedCouponCode);
            } else if (response.data && response.data.error) {
                logInDevelopment('Error:', response.data.error);
                setState(prevState => ({ ...prevState, coupon: '' }));
            }
        } catch (error) {
            console.error('Error fetching coupon:', error);
            setState(prevState => ({ ...prevState, coupon: '' }));
        }
    };

    useEffect(() => {
        if (state.subscriptionLimit && state.currentUsages !== null) {
            const subscriptionLimitLeft = calculateSubscriptionLimitLeft();
            const subscriptionLimitLeftCost = calculateSubscriptionLimitLeftCost();
            logInDevelopment('Subscription Limit Left:', subscriptionLimitLeft);
            logInDevelopment('Subscription Limit Left Cost:', subscriptionLimitLeftCost);
            if (state.subscriptionPlanId !== 'free') {
                // fetchCoupon(subscriptionLimitLeftCost);
            }
        }
    }, [state.subscriptionLimit, state.currentUsages, state.subscriptionPlanId]);

    useEffect(() => {
        if (userFromApi) {
            const userId = userFromApi.document.name;
            if (userId) {
                axios.post(`${config.ENDPOINT_API_URL}/9335347896`, { userId, sId: "" })
                    .then(response => {
                        setState(prevState => ({ ...prevState, apiData: response.data }));
                        logInDevelopment('apiData=:', response.data);
                    })
                    .catch(error => {
                        console.error('Error fetching data from API', error);
                    });
                }
            }
        }, [userFromApi]);
    
    useEffect(() => {
        const freePlanExists = state.productData.some(product => product.planId === 'free');

        if (freePlanExists === false && state.subscriptionPlanId === 'free') {
            const queryParams = new URLSearchParams(location.search);
            const productParam = queryParams.get('product');
            const cookieValue = getCookie(productParam);
            if(cookieValue === null)
            {
                setCookie(productParam, 'trial', 90);
            }
            
            setState(prevState => ({ ...prevState, cookieValue }));
            setState(prevState => ({ ...prevState, hideFreePlanInfo: 1 }));
        }
    }
    );    
        return (
            <div className="main">
                <div className="container">
                    {state.showLoader && (
                        <div className="loader-container">
                            <div className="loader-inner">
                                <RingLoader color={"#049EFF"} size={100} margin={5} />
                                <p className="loader-text">{state.loadingMessage}</p>
                            </div>
                        </div>
                    )}
                    {state.hasSubscription ? (
                        <>
                            <div className="plan-header">
                                <ProductDropDown
                                    product={state.product}
                                    userFromApi={userFromApi}
                                    apiData={state.apiData}
                                />
                                {(state.subscriptionPlanId !== 'free' || state.cookieValue === 'trial') && (
                                    <div className="item">
                                        <div className="plan-limit" style={{ display: state.hideFreePlanInfo === 1 ? 'none' : 'block' }}>
                                            <div className="plan-top">
                                                <h6>Current limit: <strong>{state.currentUsages !== null ? `${state.currentUsages} / ${state.subscriptionLimit}` : 'Loading...'}</strong></h6>
                                                <div ref={refreshButtonRef} onClick={handleRefreshClick}>
                                                    <FontAwesomeIcon
                                                        icon={faSync}
                                                        className={`refresh-icon ${state.refreshLoading ? 'spinning' : ''}`}
                                                    />
                                                </div>
                                            </div>
                                            {state.subscriptionLimit !== 0 && (
                                                <div className="progress-bar">
                                                    {state.currentUsages > 0 && (
                                                        <div className="progress-percentage" style={{ width: `${calculateUsagePercentage()}%`, backgroundColor: getFillColor() }}></div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {(state.subscriptionPlanId !== 'free' || state.cookieValue === 'trial') && (
                                <ErrorBoundary componentName="ApiInfo">
                                    <ApiInfo
                                        product={state.product}
                                        subscriptionId={state.subscriptionId}
                                        apiData={state.apiData}
                                        hideFreePlanInfo={state.hideFreePlanInfo}
                                    />
                                </ErrorBoundary>
                            )}
                        </>
                    ) : (
                        <div className="plan-header">
                            <div className="item">
                                <h3 className="page-title">My Plan</h3>
                                <h6 className="page-subtitle">You haven't subscribed for this API</h6>
                            </div>
                        </div>
                    )}
                    <ErrorBoundary componentName="PlanList">
                        <PlanList
                            productData={state.productData}
                            subscriptionPlanId={state.subscriptionPlanId}
                            hasSubscription={state.hasSubscription}
                            subscriptionPrice={state.subscriptionPrice}
                            handleUpgradeClick={handleUpgradeClick}
                            handleCancelClick={handleCancelClick}
                            loading={state.loading}
                            cookieValue={state.cookieValue}
                            product={state.product}
                            apiData={state.apiData}
                        />
                    </ErrorBoundary>
                    {mode === 'development' && (
                        <div className="mode-info">
                            <p>Current Mode: <strong>{mode}</strong></p>
                        </div>
                    )}
                    <ToastContainer autoClose={10000} />
                </div>
            </div>
        );
    };
    
    export default HomePage;
