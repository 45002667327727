// src/components/home/PlanList.js
import React from 'react';
import { logInDevelopment } from '../../utils';

const PlanListSnappy = ({
  productData,
  subscriptionPlanId,
  hasSubscription,
  subscriptionPrice,
  handleUpgradeClick,
  handleCancelClick,
  loading,
  appid,
  snappyplan
}) => {
  logInDevelopment("Pricing...");
  const handleStartTrialNowClick = async (subsId) => {
    
      // If the cookie exists, redirect to the URL
      //https://snappy.appypie.com/user/app/user/82b175f3833b?type=builder_window&back=noo
      window.location.href = `https://72snappy.pbodev.info/user/app/user/${appid}?type=builder_window&back=no&ref=api`;
    
  };
  return (
    <>
    <div class="plan-header mb-5 pb-4">
            <h2 class="page-title">Pricing</h2>
        </div>
    <div className="payment-plan-wrapper">
        <div className="plan-inner-wrapper">
        <div className="card-list-wrap card-list-single">
      {productData.map((product, index) => (
        <div className="card-item" key={index}>
          <div className={`plan-card ${product.planName.toLowerCase() === 'standard' && subscriptionPlanId !== product.planId ? 'recommended-plan':''} ${subscriptionPlanId === product.planId ? 'active' : ''}` }>
          <div className="plan-card-header">
      {product.planName.toLowerCase() === 'standard' && subscriptionPlanId !== product.planId && (
        <span className="badge badge-top">Recommended</span>
      )}
          {subscriptionPlanId === product.planId  && (product.planId !== 'free' ) ? (
            <span className="badge badge-top">
              Current Plan
            </span>
          ):(null)}
            <div className="price-wrap">
              {product.planId === 'free' ?(
                <>
                <h3 className="best-deal basic-plan-title viewonly"></h3>
                <h2><sup>$</sup>{snappyplan}</h2>
                </>
              ):(
                <>
                <h3 className={`best-deal ${product.planName.toLowerCase()}-plan-title`}>{product.planName}</h3>
                <h2><sup>$</sup>{product.price}<sub>/month</sub></h2>
                </>
              )}
              
            </div>
          </div>
        <div className="plan-card-body">
            <div className="checks-list">
              <ul>
                {product.properties && product.properties.map((property, idx) => (
                  <li key={idx}>{property}</li>
                ))}
              </ul>
            </div>
            
            {!hasSubscription || subscriptionPrice < product.price ? (
            <div className="button-wrap">
                <button className="card-cta"
                        onClick={() => handleUpgradeClick(product)}
                        disabled={!productData.length || loading === product.planId}>
                    {loading === product.planId ? (
                        <div className="loader"></div>
                    ) : (
                        <span>{hasSubscription && product.planId !== 'free' ? 'Subscribe Now' : 'Upgrade Now'}</span>
                    )}
                </button>
            </div>
          ) : (
                subscriptionPlanId === product.planId && product.planId === 'free'  ? (
                    <div className="button-wrap">
                          <button className="card-cta-bordered cta-free"
                          onClick={() => handleStartTrialNowClick(product.productName)}
                            disabled={!productData.length || loading === product.planId}>
                        {loading === product.planId ? (
                            <div className="loader"></div>
                        ) : (
                            <span>Go to User Area </span>
                        )}
                          </button>
                    </div>
                ) : (
                  subscriptionPlanId === product.planId &&  product.planId !== 'free' ? (
                  <div className="button-wrap">
                    <button className="card-cta-bordered "
                          onClick={() => handleCancelClick(product)}
                          disabled={!productData.length || loading === product.planId}>
                      {loading === product.planId ? (
                          <div className="loader"></div>
                      ) : (
                          <span>Cancel </span>
                      )}
                    </button>
                </div>
                  ):(null)
                )
            )}
        </div>  
        </div>
        </div>
      ))}
    </div>
    {/*
      <div className="skip-price"><a href="https://endpoint-console.appypie.com/login?returnUrl=/api-details#api=stable-diffusion-xl-base-10&operation=get-image">Skip</a></div>
    */}
    </div>
    </div>
    </>
  );
};

export default PlanListSnappy;